export const PRO_REQUEST_URL = `${location.protocol}//api.${window.location.host
	.split('.')
	.slice(1)
	.join('.')}/`;

export const PRO_RESOURCE_URL = `//file.${window.location.host
	.split('.')
	.slice(1)
	.join('.')}/`;

export const environment = {
	production: false,
	REQUEST_URL: 'https://api.ihook.center/',

	//location.host.includes('localhost')
	//  ? location.protocol + '//localhost:18888/'
	// : PRO_REQUEST_URL,
	RESOURCE_URL: PRO_RESOURCE_URL,
};

export const dateFormatShow = 'YYYY-MM-DD';

const getRoomList = () => {
	const list: string[] = [];
	[1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
		const b = i * 100;
		for (let index = 1; index <= 18; index++) {
			list.push((b + index).toString());
		}
	});
	return list;
};
export const room_list: any = getRoomList();

export const apartmentDetailColumns = [
	{
		key: 'electricMeterReadingLastMonth',
		dataIndex: 'electricMeterReadingLastMonth',
		title: '电表上月读数',
	},
	{
		key: 'electricMeterReadingThisMonth',
		dataIndex: 'electricMeterReadingThisMonth',
		title: '电表本月读数',
	},
	{
		key: 'hotWaterMeterReadingLastMonth',
		dataIndex: 'hotWaterMeterReadingLastMonth',
		title: '热水水表上月读数',
	},
	{
		key: 'hotWaterMeterReadingThisMonth',
		dataIndex: 'hotWaterMeterReadingThisMonth',
		title: '热水水表本月读数',
	},
	{
		key: 'coldWaterMeterReadingLastMonth',
		dataIndex: 'coldWaterMeterReadingLastMonth',
		title: '冷水水表上月读数',
	},
	{
		key: 'coldWaterMeterReadingThisMonth',
		dataIndex: 'coldWaterMeterReadingThisMonth',
		title: '冷水水表本月读数',
	},
];
